/* eslint-disable vue/multi-word-component-names */
/* eslint-disable vue/no-reserved-component-names */
import { createApp } from "vue";
import PrimeVue from "primevue/config";
import router from "./router";
import App from "./App.vue";
import ColorPicker from "primevue/colorpicker";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Material from "@primevue/themes/material";
import "/node_modules/primeflex/primeflex.css";
import { definePreset } from "@primevue/themes";
import VueDOMPurifyHTML from "vue-dompurify-html";

delete window.history.pushState; // workaround to make sure vue router doesn't try using pushState
delete window.history.replaceState; // workaround to make sure vue router doesn't try using replaceState
const app = createApp(App);

const bluePreset = definePreset(Material, {
  semantic: {
    primary: {
      50: "{indigo.50}",
      100: "{indigo.100}",
      200: "{indigo.200}",
      300: "{indigo.300}",
      400: "{indigo.400}",
      500: "{indigo.500}",
      600: "{indigo.600}",
      700: "{indigo.700}",
      800: "{indigo.800}",
      900: "{indigo.900}",
      950: "{indigo.950}",
    },
  },
});

app.use(router);
app.use(VueDOMPurifyHTML);
app.use(PrimeVue, {
  theme: {
    preset: bluePreset,
  },
});
app.component("ColorPicker", ColorPicker);
app.component("Dialog", Dialog);
app.component("Button", Button);

window.Office.onReady(() => {
  app.mount("#app");
});
