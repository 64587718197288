<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="main m-1 text-center text-100 text-xl">
    <p>Fill Magic</p>
    <div class="field grid text-base p-1 align-items-center">
      <label for="direction">Fill Direction</label>
      <div class="col">
        <select id="direction" v-model="fillDir" class="w-full p-2 border-1 border-solid surface-border border-round"
          style="appearance: auto">
          <option default>Right</option>
          <option>Left</option>
          <option>Up</option>
          <option>Down</option>
        </select>
      </div>
    </div>
    <div v-if="fillDir == 'Up' || fillDir == 'Down'">
      <div class="field grid text-base p-1 align-items-center">
        <label for="endRow" style="color: black">Set End Row</label>
        <div class="col">
          <input id="endRow" v-model="endRow" type="text"
            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
        </div>
      </div>
      <div class="field grid text-base p-1 align-items-center">
        <div class="col-6 align-items-center">
          <Button label="Current Row" @click="searchCurrent('cRow')" severity="contrast" raised />
        </div>
        <div class="col-6 align-items-center">
          <Button label="Last Active Row" @click="searchCurrent('aRow')" severity="contrast" raised />
        </div>
      </div>
    </div>
    <div v-if="fillDir == 'Left' || fillDir == 'Right'">
      <div class="field grid text-base p-1 align-items-center"></div>
      <label for="endRow" style="color: black">Set End Column</label>
      <div class="col">
        <input id="endCol" v-model="endCol" type="text"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
      </div>
      <div class="field grid text-base p-1 align-items-center">
        <div class="col-6 align-items-center">
          <Button label="Current Column" @click="searchCurrent('cCol')" severity="contrast" raised />
        </div>
        <div class="col-6 align-items-center">
          <Button label="Last Active Column" @click="searchCurrent('aCol')" severity="contrast" raised />
        </div>
      </div>
    </div>
    <div class="align-items-center text-lg text-primary align-items-center">
      Features to Fill:
    </div>
    <div class="formgrid grid p-3">
      <div class="field-checkbox col-6">
        <input type="checkbox" id="fullCopy" @change="opCheck('fullCopy')" v-model="fullCopy" />
        <label for="fullCopy" style="color: black">Formulas & Formats</label>
      </div>
      <div class="field-checkbox col-6">
        <input type="checkbox" id="pasteValues" @change="opCheck('pasteValues')" v-model="pasteValues" />
        <label for="pasteValues" style="color: black">Values Only</label>
      </div>
      <div class="field-checkbox col-6">
        <input type="checkbox" id="formulas" @change="opCheck('formulas')" v-model="formulas" />
        <label for="formulas" style="color: black">Formulas Only</label>
      </div>
      <div class="field-checkbox col-6">
        <input type="checkbox" id="formats" @change="opCheck('formats')" v-model="formats" />
        <label for="formats" style="color: black">Formats Only</label>
      </div>
    </div>
    <div>
      <p>
        <Button label="Magically Fill!" @click="goFillMagic()" severity="warning" raised />
      </p>
    </div>
    <div class="field grid text-base p-1 align-items-center">
      <div class="col-6">
        <Button label="Save" @click="saveFillMagic()" severity="info" style="min-width: 50%; min-height: 50%" raised />
      </div>
      <div class="col-6">
        <Button label="Load" @click="getData()" severity="info" style="min-width: 50%; min-height: 50%" raised />
      </div>
    </div>
  </div>
  <ErrorDialog />
</template>

<script setup>
import { processRange } from "../methods/fillMagic.js";
import {
  dataStore,
  getUsedRange,
  getCurrent,
  keepLetters,
  keepNumbers,
  columnLetterToIndex,
} from "../methods/general.js";
import { fillMagicSettings, errorMessage } from "../methods/store.js";
import { ref, onMounted } from "vue";
import ErrorDialog from "../components/ErrorDialog.vue";

let fillDir = ref(fillMagicSettings.fillDir);
let pasteValues = ref(fillMagicSettings.pasteValues);
let formulas = ref(fillMagicSettings.formulas);
let formats = ref(fillMagicSettings.formats);
let fullCopy = ref(fillMagicSettings.fullCopy);
let endRow = ref(fillMagicSettings.endRow);
let endCol = ref(fillMagicSettings.endCol);

const getData = async () => {
  //get data from store
  try {
    fillDir.value = await dataStore("retrieve", "fillDir", null, false);
    pasteValues.value = await dataStore("retrieve", "pasteValues", null, false);
    formulas.value = await dataStore("retrieve", "formulas", null, false);
    formats.value = await dataStore("retrieve", "formats", null, false);
    fullCopy.value = await dataStore("retrieve", "fullCopy", null, false);
    endRow.value = await dataStore("retrieve", "endRow", null, false);
    endCol.value = await dataStore("retrieve", "endCol", null, false);
  } catch (error) {
    console.error("Error: " + error.stack);
  }
};

onMounted(async () => {
  //mount stored data on load
  await getData();
  if (
    fillDir.value == "error" ||
    pasteValues.value == "error" ||
    formulas.value == "error" ||
    fullCopy.value == "error" ||
    endRow.value == "error" ||
    endCol.value == "error" ||
    fillDir.value == null ||
    pasteValues.value == null ||
    formulas.value == null ||
    fullCopy.value == null ||
    endRow.value == null ||
    endCol.value == null
  ) {
    //if there are errors, load defaults
    fillDir.value = fillMagicSettings.fillDir;
    pasteValues.value = fillMagicSettings.pasteValues;
    formulas.value = fillMagicSettings.formulas;
    formats.value = fillMagicSettings.formats;
    fullCopy.value = fillMagicSettings.fullCopy;
    endRow.value = fillMagicSettings.endRow;
    endCol.value = fillMagicSettings.endCol;
  }
});

async function goFillMagic() {
  //get the current values from the selection and convert the endCol to an index for troubleshooting
  const currRow = (await getCurrent("rowIndex")) + 1;
  const currCol = (await getCurrent("columnIndex")) + 1;
  //convert the column letter to an index, so they can be compared.
  const endColIndex = columnLetterToIndex(endCol.value);
  //converted value starts at 1 not at zero, so don't need to add one

  //error checking and confirming inputs
  if (fillDir.value == "Up" || fillDir.value == "Down") {
    endRow.value = Number(endRow.value);
    if (
      endRow.value == null ||
      endRow.value == "" ||
      endRow.value == undefined ||
      isNaN(endRow.value) ||
      !isFinite(endRow.value) ||
      !Number.isInteger(endRow.value)
    ) {
      errorMessage.header = "Please set the end row!";
      errorMessage.message = "Row must be an integer.";
      errorMessage.show = true;
      return;
    } else if (fillDir.value == "Down" && endRow.value < currRow) {
      errorMessage.header = "Please check the end row!";
      errorMessage.message =
        "End Row must be greater than the currently selected row.";
      errorMessage.show = true;
      return;
    } else if (fillDir.value == "Up" && endRow.value > currRow) {
      errorMessage.header = "Please check the end row!";
      errorMessage.message =
        "End Row must be less than the currently selected row.";
      errorMessage.show = true;
      return;
    }
  } else if (fillDir.value == "Left" || fillDir.value == "Right") {
    const columnRegex = /^[A-Z]+$/;
    if (
      endCol.value == null ||
      endCol.value == "" ||
      endCol.value == undefined ||
      typeof endCol.value !== "string" ||
      endCol.value.length > 2 ||
      !columnRegex.test(endCol.value)
    ) {
      errorMessage.header = "Please set the end column!";
      errorMessage.message = "Column must be one or two letters.";
      errorMessage.show = true;
      return;
    } else if (fillDir.value == "Right" && endColIndex < currCol) {
      errorMessage.header = "Please check the end column!";
      errorMessage.message =
        "End Column must be greater than the currently selected column.";
      errorMessage.show = true;
      return;
    } else if (fillDir.value == "Left" && endColIndex > currCol) {
      errorMessage.header = "Please check the end column!";
      errorMessage.message =
        "End Column must be less than the currently selected column.";
      errorMessage.show = true;
      return;
    }
  }
  //make it so
  processRange(
    fillDir.value,
    pasteValues.value,
    formulas.value,
    formats.value,
    fullCopy.value,
    endRow.value,
    endCol.value
  );
  //after process, save settings to localStore and to in spreadsheet dataStore
  fillMagicSettings.fillDir = fillDir.value;
  fillMagicSettings.pasteValues = pasteValues.value;
  fillMagicSettings.formulas = formulas.value;
  fillMagicSettings.formats = formats.value;
  fillMagicSettings.fullCopy = fullCopy.value;
  fillMagicSettings.endRow = endRow.value;
  fillMagicSettings.endCol = endCol.value;
  saveFillMagic();
}

async function saveFillMagic() {
  //save data to the office/excel store.
  await dataStore("store", "fillDir", fillDir.value);
  await dataStore("store", "pasteValues", pasteValues.value);
  await dataStore("store", "formulas", formulas.value);
  await dataStore("store", "formats", formats.value);
  await dataStore("store", "fullCopy", fullCopy.value);
  await dataStore("store", "endRow", endRow.value);
  await dataStore("store", "endCol", endCol.value);
}

function opCheck(option) {
  //Options: ['pasteValues', 'formulas','multi', 'copyPaste', 'fillFunc']
  //check if option was selected or deselected as part of click

  if (option == "formulas" && formulas.value == true) {
    pasteValues.value = false;
    fullCopy.value = false;
    formats.value = false;
  }
  if (option == "pasteValues" && pasteValues.value == true) {
    formulas.value = false;
    formats.value = false;
    fullCopy.value = false;
  }
  if (option == "formats" && formats.value == true) {
    formulas.value = false;
    pasteValues.value = false;
    fullCopy.value = false;
  }
  if (option == "fullCopy" && fullCopy.value == true) {
    formulas.value = false;
    pasteValues.value = false;
    formats.value = false;
  }
  if (
    fullCopy.value == false &&
    formulas.value == false &&
    pasteValues.value == false &&
    formats.value == false
  ) {
    fullCopy.value = true;
  }
}

async function searchCurrent(req) {
  //functions to get the currently selected row or column and apply it to the settings.
  var setVal = "";
  var curr = "";
  if (req == "cCol" || req == "cRow") {
    curr = await getCurrent();

    curr = curr.split("!", 2)[1];

    if (curr == null) {
      return null;
    } else {
      if (req == "cCol") {
        setVal = keepLetters(curr);

        endCol.value = setVal;
      } else {
        setVal = keepNumbers(curr);

        endRow.value = setVal;
      }
    }
  } else {
    curr = await getUsedRange();

    curr = curr.split(":", 2)[1];

    if (curr == null) {
      return null;
    } else {
      if (req == "aCol") {
        setVal = keepLetters(curr);

        endCol.value = setVal;
      } else {
        setVal = keepNumbers(curr);

        endRow.value = setVal;
      }
    }
  }
}
</script>
