import { errorMessage, quickInput } from "./store";
import { watch } from "vue";

//trim spaces from both sides of a string in a cell
const trimExtraSpaces = () => {
  window.Excel.run(function (context) {
    let range = context.workbook.getSelectedRange();
    range.load("values");
    return context.sync().then(async function () {
      let values = range.values;
      for (let i = 0; i < values.length; i++) {
        for (let j = 0; j < values[i].length; j++) {
          if (typeof values[i][j] === "string") {
            values[i][j] = values[i][j].trim();
          }
        }
      }
      range.values = values;
      return context.sync();
    });
  });
};

//slice cells, start on start, keep the # of characters indicated
const sliceCells = (start, keep) => {
  //check for valid input
  // Check if start or keep aren't integers
  if (!Number.isInteger(start) || !Number.isInteger(keep)) {
    console.log("ERR: Invalid input for start or keep.");
    errorMessage.show = true;
    errorMessage.message = "Input must be integers.";
    return;
  }

  if (start < 1 || keep < 1) {
    console.log("ERR: Bad Start Character Count.");
    errorMessage.show = true;
    errorMessage.message = "Inputs must be 1 or greater.";
    return;
  }
  window.Excel.run(function (context) {
    let range = context.workbook.getSelectedRange();
    range.load("values");
    return context.sync().then(async function () {
      let values = range.values;
      let startIndex = start - 1;
      let keepIndex = startIndex + keep;
      for (let i = 0; i < values.length; i++) {
        for (let j = 0; j < values[i].length; j++) {
          if (typeof values[i][j] === "string") {
            values[i][j] = values[i][j].slice(startIndex, keepIndex);
          }
        }
      }
      range.values = values;
      return context.sync();
    });
  });
};

// Split each cell in the selected range based on the input character
const splitCells = (
  splitChar,
  keepLeft,
  keepRight,
  keepSplitLeft,
  keepSplitRight
) => {
  window.Excel.run(function (context) {
    let range = context.workbook.getSelectedRange();
    //create new range for the right side of the split. This will be the next column over from the original range.
    let newRange = range.getOffsetRange(0, 1);
    newRange.load("values");
    range.load("values");
    range.load("columnCount");
    let checkEmpty = context.workbook.functions.countA(newRange);
    checkEmpty.load("value");
    return context.sync().then(async function () {
      if (range.columnCount > 1) {
        console.log("ERR: muiltiple columns selected.");
        errorMessage.show = true;
        errorMessage.message = "Please select a single column.";
        return;
      } else if (checkEmpty.value > 0) {
        //there is data in the next column. Ask the user if they want to overwrite it. If not, do nothing.
        quickInput.show = true;
        quickInput.title =
          "There are 1 or more cells with data in the column to the right of the selected column. Do you want to overwrite them?";
        quickInput.inputLabels = [];
        quickInput.inputValues = [];
        quickInput.inputTypes = [];
        // Wait for modal to close before proceeding
        await new Promise((resolve) => {
          const unwatch = watch(
            () => quickInput.show,
            (newValue) => {
              if (!newValue) {
                unwatch();
                resolve();
              }
            }
          );
        });
        // Proceed with the function after modal is closed
        if (quickInput.approve) {
          console.log("proceeding");
        } else {
          console.log("Split Cells Cancelled By User");
          return;
        }
      }
      let values = range.values;
      let newValues = newRange.values;

      for (let i = 0; i < values.length; i++) {
        for (let j = 0; j < values[i].length; j++) {
          if (typeof values[i][j] === "string") {
            let splitValues = values[i][j].split(splitChar, 2);
            if (keepLeft && keepSplitLeft) {
              values[i][j] = splitValues[0] + splitChar; // Left side of the split + split
            } else if (keepLeft) {
              values[i][j] = splitValues[0]; // Left side of the split
            } else if (keepSplitLeft) {
              values[i][j] = splitChar; // Keep only the Split with Left side
            } else {
              values[i][j] = splitValues[1]; // Move right side to left side
            }
            if (keepRight && keepSplitRight) {
              newValues[i][j] = splitChar + splitValues[1]; // Right side of the split + split
            } else if (keepRight) {
              newValues[i][j] = splitValues[1]; // Right side of the split
            } else if (keepSplitRight) {
              newValues[i][j] = splitChar; // Keep only the Split with Right side
            } else {
              //do nothing with new values
            }
          }
        }
      }
      range.values = values;
      newRange.values = newValues;
      context.sync();
      return;
    });
  });
  return;
};

export { trimExtraSpaces, sliceCells, splitCells };
