<template>
  <div class="main-wrapper">
    <div class="grid m-1">
      <div class="col-7 align-items-left">
        <router-link to="/" class="no-underline primary">
          Home
        </router-link> <span class="text-gray-500">{{ route.path }}</span>
      </div>
      <div class="col-2 align-items-right" style="text-align: right;">
        <label for="helpToggle" style="color: #999; text-align: right; font-size: 0.8em; display: block;">
          Enable Help</label>
      </div>
      <div class="col-3 align-items-left" style="text-align: left;">
        <ToggleSwitch id=" helpToggle" v-model="helpToggle.show" />
      </div>
    </div>
    <div class="main-container text-center">
      <img alt="Excel Tools logo" src="icon-128.png" />
      <br />
      <router-view />
    </div>
    <Card class="custom-card pt-3">
      <template #content>
        <p class="px-2">NOTE: Edits made by these tools are PERMANENT and CANNOT be undone.</p>
        <p class="text-center bold p-2"><u>Save your work now.</u></p>
      </template>
    </Card>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import ToggleSwitch from 'primevue/toggleswitch';
import { helpToggle } from './methods/store.js'
import Card from 'primevue/card';
const route = useRoute();

</script>

<style>
body {
  background-color: #ffffff;
}
</style>
<style scoped>
/* If you want to target the inner content area of the card */
.custom-card ::v-deep .p-card-body {
  background-color: #570000;
  color: #ffffff;
  padding: 5px;

}

.bold {
  font-weight: bold
}

.text-right {
  text-align: right;
}
</style>
