import { reactive } from "vue";

const colorDialog = reactive({
  show: false,
  primaryColor: "#e6e6e6",
  secondaryColor: "#ffffff",
});

const lambdaConfirm = reactive({
  show: false,
  result: "",
});

const fillMagicSettings = reactive({
  fillDir: "Right",
  pasteValues: false,
  formulas: false,
  formats: false,
  fullCopy: true,
  endRow: "1",
  endCol: "A",
});

const copyPasteToolSettings = reactive({
  pasteValues: false,
  formulas: false,
  formats: false,
  fullCopy: true,
  exactCopy: false,
  skipBlanks: false,
  startCopy: "A1",
  endCopy: "A1",
  startPaste: "B1",
  sheetCopy: "Sheet1",
  sheetPaste: "Sheet1",
});

const errorMessage = reactive({
  show: false,
  header: "",
  message: "",
});

const gptResponse = reactive({
  value: "",
});

const helpToggle = reactive({
  show: false,
});

const quickInput = reactive({
  show: false,
  approve: false,
  inputLabels: [],
  inputValues: [],
});

export {
  colorDialog,
  lambdaConfirm,
  fillMagicSettings,
  copyPasteToolSettings,
  errorMessage,
  gptResponse,
  helpToggle,
  quickInput,
};
