/* eslint-disable no-unused-vars */
import { gptResponse } from "./store";

async function askChatGPT(question, context = "") {
  try {
    const response = await fetch(
      "https://askgpt-excel.points-unknown.workers.dev/?token=05EScvWT6crQUDUDFwHMiwK8BpLRrrYGuccSuFDIvzr6IAbg",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: `
You are an excel expert coaching a user on how to do tasks in excel.
Users will ask you questions and you will provide a response that is clear and concise, and easy to understand.
The user may provide additional context, in the form of cell contents from their excel workbook, to help you answer their question.
\n\n
It is okay for you to be brief. You can offer short explanations, but don't go into too much detail.
You do not need to provide examples unless specifically asked in the question. 
If you do not have a high degree of certainty in the answer, it is okay to say "I don't know".
\n\n
Please provide your response in markdown format. Use code blocks for any formulas or functions you mention. Use bold text for any 
important points you want to make. Use bullet points for lists. Use links for any relevant documentation or resources. 
Use the context provided by the user to help answer their question, but don't assume that the context is always 100% accurate.
\n\n
This is the question:\n
${question}
\n\n
The user provided the following context:\n
${context}`,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error so it can be handled by the caller
  }
}

export async function onAskQuestionButtonClick(question, includeContext) {
  try {
    await window.Excel.run(async (context) => {
      let contextText = "";

      if (includeContext) {
        const selectedRange = context.workbook.getSelectedRange();
        selectedRange.load("formulas");
        await context.sync();
        contextText = selectedRange.formulas.flat().join(" ");
        await context.sync();
      }

      const answer = await askChatGPT(question, contextText);
      await context.sync();
      gptResponse.value = answer;
      return answer;
    });
  } catch (error) {
    console.error(error);
  }
}

/*
  //****** BELOW IS THE CODE FOR THE CLOUDFLARE WORKER
  //THAT SHOULD BE USED TO KEEP THE CHATGPT API KEY SECRET

addEventListener('fetch', event => {
  event.respondWith(handleRequest(event.request))
})

async function handleRequest(request) {
  const url = new URL(request.url);
  const token = url.searchParams.get('token');

  if (!token || token !== 'YOUR_SECRET_TOKEN') {
    return new Response('Unauthorized', { status: 401 });
  }

  const { question } = await request.json();

  const response = await fetch('YOUR_CHATGPT_API_ENDPOINT', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer YOUR_API_KEY'
    },
    body: JSON.stringify({
      prompt: question,
      max_tokens: 250
    })
  });

  const data = await response.json();
  return new Response(JSON.stringify(data.choices[0].text.trim()), {
    headers: { 'Content-Type': 'application/json' }
  });
}

  */
