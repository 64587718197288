<template>
  <Dialog v-model:visible="colorDialog.show" modal header="Select Colors to Alternate" :style="{ width: '50rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <form id="colorPicker">
      <p>
        <label for="primaryColor">Primary Color: </label>
        <ColorPicker v-model="primaryColor" placeholder="111111" />
      </p>
      <p>
        <label for="secondaryColor">Secondary Color: </label>
        <ColorPicker v-model="secondaryColor" placeholder="#000000" />
      </p>
      <Button label="Submit" @click="submit()" raised />
    </form>
  </Dialog>
</template>

<script setup>
import { colorDialog } from "@/methods/store";
import { ref } from "vue";
import { alternateColors } from "../methods/formats.js";

var primaryColor = ref(colorDialog.primaryColor);
var secondaryColor = ref(colorDialog.secondaryColor);

async function submit() {
  console.log("submit");
  colorDialog.primaryColor = primaryColor.value;
  colorDialog.secondaryColor = secondaryColor.value;
  await alternateColors(primaryColor.value, secondaryColor.value);
  colorDialog.show = false;
}
</script>

<style lang="css" scoped></style>
