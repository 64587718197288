const copyPaste = async (data) => {
  //data consists of:
  /*  sheetCopy
    sheetPaste
    startCopy
    endCopy
    copyRange
    startPaste
    pasteValues
    formulas
    formats
    fullCopy,
    skipBlanks
    exactCopy
 */

  await window.Excel.run(async (context) => {
    let copySheet = context.workbook.worksheets.getItem(data.sheetCopy);
    let pasteSheet = context.workbook.worksheets.getItem(data.sheetPaste);
    let copyRange = copySheet.getRange(data.copyRange);
    let pasteRange = pasteSheet.getRange(data.startPaste);
    //Range.copyFrom has three optional parameters
    //copyFrom(sourceRange: Range | RangeAreas | string, copyType?: Excel.RangeCopyType, skipBlanks?: boolean, transpose?: boolean): void;
    // Copy a range, omitting the blank cells so existing data is not overwritten in those cells.
    /*  copyType specifies what data gets copied from the source to the destination.
        Excel.RangeCopyType.formulas transfers the formulas in the source cells and preserves the relative 
        positioning of those formulas' ranges. Any non-formula entries are copied as-is.
        Excel.RangeCopyType.values copies the data values and, in the case of formulas, the result of the formula.
        Excel.RangeCopyType.formats copies the formatting of the range, including font, color, and other format settings, but no values.
        Excel.RangeCopyType.all (the default option) copies both data and formatting, preserving cells' formulas if found.
        skipBlanks sets whether blank cells are copied into the destination. When true, copyFrom skips blank cells in the source range. 
        Skipped cells will not overwrite the existing data of their corresponding cells in the destination range. The default is false.
        transpose determines whether or not the data is transposed, meaning its rows and columns are switched, into the source location. 
        A transposed range is flipped along the main diagonal, so rows 1, 2, and 3 will become columns A, B, and C. 
    */
    if (data.exactCopy) {
      //exact copy....
      //iterate through each cell in the copyRange and copy the formula exactly to the pasteRange
      let formulas = [];
      copyRange.load("rowCount, columnCount");
      await context.sync();
      for (let row = 0; row < copyRange.rowCount; row++) {
        let formulaRow = [];
        for (let col = 0; col < copyRange.columnCount; col++) {
          let cell = copyRange.getCell(row, col);
          cell.load("formulas");
          await context.sync();
          formulaRow.push(cell.formulas[0][0]);
        }
        formulas.push(formulaRow);
      }

      // Now copy the formulas to the pasteRange
      for (let row = 0; row < formulas.length; row++) {
        for (let col = 0; col < formulas[row].length; col++) {
          pasteRange.getCell(row, col).formulas = [[formulas[row][col]]];
        }
      }
    } else if (data.pasteValues) {
      pasteRange.copyFrom(
        copyRange,
        window.Excel.RangeCopyType.values, //copyType
        data.skipBlanks, // skipBlanks
        false // transpose
      );
    } else if (data.formats) {
      pasteRange.copyFrom(
        copyRange,
        window.Excel.RangeCopyType.formats, //copyType
        data.skipBlanks, // skipBlanks
        false // transpose
      );
    } else if (data.formulas) {
      pasteRange.copyFrom(
        copyRange,
        window.Excel.RangeCopyType.formulas, //copyType
        data.skipBlanks, // skipBlanks
        false // transpose
      );
    } else {
      pasteRange.copyFrom(
        copyRange,
        window.Excel.RangeCopyType.all, //copyType
        data.skipBlanks, // skipBlanks
        false // transpose
      );
    }
    await context.sync();
  });
};

// Helper function to convert column letter to index (A=0, B=1, etc.)
function getColumnIndex(column) {
  return (
    column
      .split("")
      .reduce((acc, char) => acc * 26 + char.charCodeAt(0) - 64, 0) - 1
  );
}

function checkOverlappingCells(
  sheetCopy,
  sheetPaste,
  startCopy,
  endCopy,
  startPaste
) {
  if (sheetCopy !== sheetPaste) {
    return false; // No overlap if sheets are different
  }

  const copyStart = parseCellReference(startCopy);
  const copyEnd = parseCellReference(endCopy);
  const pasteStart = parseCellReference(startPaste);

  const copyWidth = copyEnd.col - copyStart.col + 1;
  const copyHeight = copyEnd.row - copyStart.row + 1;
  const pasteEnd = {
    col: pasteStart.col + copyWidth - 1,
    row: pasteStart.row + copyHeight - 1,
  };

  return (
    pasteStart.col <= copyEnd.col &&
    pasteEnd.col >= copyStart.col &&
    pasteStart.row <= copyEnd.row &&
    pasteEnd.row >= copyStart.row
  );
}

function parseCellReference(cellRef) {
  const match = cellRef.match(/([A-Z]+)(\d+)/);
  return {
    col: getColumnIndex(match[1]),
    row: parseInt(match[2]),
  };
}

export { copyPaste, checkOverlappingCells, getColumnIndex, parseCellReference };
