<template>
    <Dialog v-model:visible="quickInput.show" modal :header="quickInput.title" :style="{ width: '50rem' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <form id="quickInput">
            <div class="field grid text-base p-1 align-items-center"
                v-for="(inputLabel, index) in quickInput.inputLabels" :key="index">
                <div class="col-6 align-items-center">
                    <label for="input">{{ inputLabel }}</label>
                </div>
                <div class="col-6 align-items-center">
                    <input id="inputValue" v-model="quickInput.inputValues[index]" :type="quickInput.inputTypes[index]"
                        :class="[quickInput.inputTypes[index] == 'checkbox' ? '' : textClass]" />
                </div>
            </div>
            <div class="field grid text-base p-1 align-items-center">
                <div class="col-6 align-items-center">
                    <Button label="Cancel" @click="cancel()" severity="warn" raised />
                </div>
                <div class="col-6 align-items-center">
                    <Button label="Continue" @click="submit()" severity="success" raised />
                </div>
            </div>
        </form>
    </Dialog>
</template>

<script setup>
import { quickInput } from "@/methods/store";
import { ref } from "vue";


function submit() {
    quickInput.show = false
    quickInput.approve = true
}
function cancel() {
    quickInput.show = false
    quickInput.approve = false
}

var textClass = ref("text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full")
</script>

<style lang="css" scoped></style>
