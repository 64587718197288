<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="main m-1 text-center text-100 text-xl">
    <p>Copy/Paste Tools</p>
    <Card class="custom-card text-base p-1" v-if="helpToggle.show">
      <template #content>
        <p>This tool allows you to copy and paste ranges of cells between sheets in the same workbook in a variety of
          different ways.
          To use, enter the addresses of cells you want to copy/paste or select the desired range and then click "Get
          From Selection". Select your desired 'features', then click 'Copy & Paste with Selected Settings!'
        </p>
        <p>
          Yes, this is not entirely novel. A few things it can help with:
        </p>
        <ul class="text-left">
          <li>Copying the same range multiple times, but to different paste locations.</li>
          <li>Copying a range without translating the formulas to the new range (so the references stay without having
            to code in $'s)</li>
          <li>Potentially quicker access to 'paste special'</li>
        </ul>
      </template>
    </Card>
    <div class="field grid text-base p-1 align-items-center">
      <div class="col-6 align-items-center">
        <h3>Copy Range</h3>
      </div>
      <div class="col-6 align-items-center">
        <Button label="Get From Selection" @click="getCurrentSelection('copy')" severity="info" raised />
      </div>
      <div class="col-6 align-items-center">
        <label for="copyStart">Sheet to Copy From</label>
      </div>
      <div class="col-6 align-items-center">
        <input id="sheetCopy" v-model="sheetCopy" type="text"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
      </div>
      <div class="col-6 align-items-center">
        <label for="copyStart">First Cell in Copy Range</label>
      </div>
      <div class="col-6 align-items-center">
        <input id="startCopy" v-model="startCopy" type="text"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
          @input="startCopy = $event.target.value.toUpperCase()" />
      </div>
      <div class="col-6 align-items-center">
        <label for="endCopy">Last Cell in Copy Range</label>
      </div>
      <div class="col-6 align-items-center">
        <input id="endCopy" v-model="endCopy" type="text"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
          @input="endCopy = $event.target.value.toUpperCase()" />
      </div>
    </div>
    <div class="field grid text-base p-1 align-items-center">
      <div class="col-6 align-items-center">
        <h3>Paste Range</h3>
      </div>
      <div class="col-6 align-items-center">
        <Button label="Get From Selection" @click="getCurrentSelection('paste')" severity="info" raised
          v-if="!pasteAtSel" />
      </div>
      <div class="col-6 align-items-center" v-if="!pasteAtSel">
        <label for="copyStart">Sheet to Paste Into</label>
      </div>
      <div class="col-6 align-items-center" v-if="!pasteAtSel">
        <input id="startCopy" v-model="sheetPaste" type="text"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
      </div>
      <div class="col-6 align-items-center" v-if="!pasteAtSel">
        <label for="startPaste">Cell to Start Paste</label>
      </div>
      <div class="col-6 align-items-center" v-if="!pasteAtSel">
        <input id="startPaste" v-model="startPaste" type="text"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
          @input="startPaste = $event.target.value.toUpperCase()" />
      </div>
      <div class="col-6 align-items-center">
        <label for="pasteAtSel">Paste at Selected Cell</label>
      </div>
      <div class="col-6 align-items-center">
        <input id="pasteAtSel" v-model="pasteAtSel" type="checkbox" />
      </div>

    </div>
    <div class="align-items-center text-lg text-primary align-items-center">
      Features to Fill:
    </div>
    <div class="formgrid grid p-3">
      <div class="field-checkbox col-6">
        <input type="checkbox" id="fullCopy" @change="opCheck('fullCopy')" v-model="fullCopy" />
        <label for="fullCopy" style="color: black">Formulas & Formats</label>
      </div>
      <div class="field-checkbox col-6">
        <input type="checkbox" id="pasteValues" @change="opCheck('pasteValues')" v-model="pasteValues" />
        <label for="pasteValues" style="color: black">Values Only</label>
      </div>
      <div class="field-checkbox col-6">
        <input type="checkbox" id="formulas" @change="opCheck('formulas')" v-model="formulas" />
        <label for="formulas" style="color: black">Formulas Only</label>
      </div>
      <div class="field-checkbox col-6">
        <input type="checkbox" id="formats" @change="opCheck('formats')" v-model="formats" />
        <label for="formats" style="color: black">Formats Only</label>
      </div>
      <div class="field-checkbox col-6">
        <input type="checkbox" id="formats" @change="opCheck('exactCopy')" v-model="exactCopy" />
        <label for="formats" style="color: black">No Formula Translation</label>
      </div>
      <div class="field-checkbox col-6">
        <input type="checkbox" id="formats" @change="opCheck('skipBlanks')" v-model="skipBlanks" />
        <label for="formats" style="color: black">Skip Blanks</label>
      </div>
    </div>
    <div class="col-12 align-items-center py-0">
      <Message severity="error" :closable="true">
        You WILL NOT BE ABLE TO UNDO. Please make sure you have a backup of your workbook.<br />
        It is recommended that you save now and <u>turn autosave off</u> before running.
      </Message>
    </div>
    <div>
      <p>
        <Button label="Copy & Paste with Selected Settings!" @click="goCopyPaste()" raised />
      </p>
    </div>
    <div class="field grid text-base p-1 align-items-center">
      <div class="col-6">
        <Button label="Save" @click="saveCopyPaste()" severity="info" style="min-width: 50%; min-height: 50%" raised />
      </div>
      <div class="col-6">
        <Button label="Load" @click="getData()" severity="info" style="min-width: 50%; min-height: 50%" raised />
      </div>
    </div>
  </div>
  <ErrorDialog />
</template>

<script setup>
import { checkOverlappingCells, copyPaste, parseCellReference } from "../methods/copyPaste.js";
import {
  dataStore
} from "../methods/general.js";
import { copyPasteToolSettings, errorMessage, helpToggle } from "../methods/store.js";
import { ref, onMounted } from "vue";
import ErrorDialog from "../components/ErrorDialog.vue";
import Message from "primevue/message"
import Card from "primevue/card"


let pasteValues = ref(copyPasteToolSettings.pasteValues);
let formulas = ref(copyPasteToolSettings.formulas);
let formats = ref(copyPasteToolSettings.formats);
let fullCopy = ref(copyPasteToolSettings.fullCopy);
let startCopy = ref(copyPasteToolSettings.startCopy);
let endCopy = ref(copyPasteToolSettings.endCopy);
let startPaste = ref(copyPasteToolSettings.startPaste);
let sheetCopy = ref(copyPasteToolSettings.sheetCopy);
let sheetPaste = ref(copyPasteToolSettings.sheetPaste);
let skipBlanks = ref(copyPasteToolSettings.skipBlanks);
let exactCopy = ref(copyPasteToolSettings.exactCopy);
let pasteAtSel = ref(copyPasteToolSettings.pasteAtSel)


const getData = async () => {
  //get data from store
  try {
    pasteValues.value = await dataStore("retrieve", "pasteValues", null, false);
    formulas.value = await dataStore("retrieve", "formulas", null, false);
    formats.value = await dataStore("retrieve", "formats", null, false);
    fullCopy.value = await dataStore("retrieve", "fullCopy", null, false);
    startCopy.value = await dataStore("retrieve", "startCopy", null, false);
    endCopy.value = await dataStore("retrieve", "endCopy", null, false);
    startPaste.value = await dataStore("retrieve", "startPaste", null, false);
    sheetCopy.value = await dataStore("retrieve", "sheetCopy", null, false);
    sheetPaste.value = await dataStore("retrieve", "sheetPaste", null, false);
    skipBlanks.value = await dataStore("retrieve", "skipBlanks", null, false);
    exactCopy.value = await dataStore("retrieve", "exactCopy", null, false);
    pasteAtSel.value = await dataStore("retrieve", "pasteAtSel", null, false);
  } catch (error) {
    //console.error("Error: " + error.stack);
    console.error("Error: getting data");
  }
};

onMounted(async () => {
  //mount stored data on load
  await getData();
  if (
    pasteValues.value == "error" ||
    formulas.value == "error" ||
    fullCopy.value == "error" ||
    startCopy.value == "error" ||
    endCopy.value == "error" ||
    endCopy.value == "error" ||
    startPaste.value == "error" ||
    sheetCopy.value == "error" ||
    sheetPaste.value == "error" ||
    skipBlanks.value == "error" ||
    exactCopy.value == "error" ||
    pasteAtSel.value == "error" ||
    pasteValues.value == null ||
    formulas.value == null ||
    fullCopy.value == null ||
    startCopy.value == null ||
    endCopy.value == null ||
    startPaste.value == null ||
    sheetCopy.value == null ||
    sheetPaste.value == null ||
    skipBlanks.value == null ||
    exactCopy.value == null ||
    pasteAtSel.value == null
  ) {
    //if there are errors, load defaults

    pasteValues.value = copyPasteToolSettings.pasteValues;
    formulas.value = copyPasteToolSettings.formulas;
    formats.value = copyPasteToolSettings.formats;
    fullCopy.value = copyPasteToolSettings.fullCopy;
    startCopy.value = copyPasteToolSettings.startCopy;
    endCopy.value = copyPasteToolSettings.endCopy;
    startPaste.value = copyPasteToolSettings.startPaste;
    sheetCopy.value = copyPasteToolSettings.sheetCopy;
    sheetPaste.value = copyPasteToolSettings.sheetPaste;
    skipBlanks.value = copyPasteToolSettings.skipBlanks;
    exactCopy.value = copyPasteToolSettings.exactCopy;
    pasteAtSel.value = copyPasteToolSettings.pasteAtSel;
  }
});

async function goCopyPaste() {

  //get list of worksheets for error checking sheet inputs
  try {
    let worksheetNames = [];
    let selectedCell = ""
    await window.Excel.run(async (context) => {
      const workbook = context.workbook;
      const worksheets = workbook.worksheets;
      const selection = workbook.getSelectedRange();
      selection.load("address");
      worksheets.load("items/name");
      await context.sync();
      worksheetNames = worksheets.items.map(sheet => sheet.name);
      selectedCell = selection.address //.replace(/!/g, ":")
    });
    if (!worksheetNames.includes(sheetCopy.value) || !worksheetNames.includes(sheetPaste.value)) {
      errorMessage.header = "Please check the sheet names."
      errorMessage.message = "One or more of the sheets does not exist in the current workbook. Only sheets within the current workbook are currently supported."
      errorMessage.show = true
      return
    }
    if (pasteAtSel.value) {
      sheetPaste.value = selectedCell.split("!")[0]
      startPaste.value = selectedCell.split("!")[1].split(":")[0]
      console.log(`pasteAtSel: ${pasteAtSel.value} and startPaste: ${startPaste.value} and sheetPaste: ${sheetPaste.value}`)
    }
  } catch (error) {
    console.error("Error getting worksheets:", error);
  }

  //cell/range error checking inputs
  if (
    startCopy.value.toString().match(/[A-Z]+[0-9]+/g) == null ||
    endCopy.value.toString().match(/[A-Z]+[0-9]+/g) == null
  ) {
    errorMessage.header = "Please check copy cells";
    errorMessage.message = "Copy range values must be in the format of \"A1\" or \"AA100\".";
    errorMessage.show = true;
    return;
  } else if (startPaste.value.toString().match(/[A-Z]+[0-9]+/g) == null) {
    errorMessage.header = "Please check paste cell.";
    errorMessage.message =
      "Paste range values must be in the format of \"A1\" or \"AA100\".";
    errorMessage.show = true;
    return;
  } else if (startCopy.value == startPaste.value && sheetCopy.value == sheetPaste.value) {
    errorMessage.header = "Please check copy and paste cells."
    errorMessage.message = "Copy and paste cells should not be the same."
    errorMessage.show = true
    return
  }


  //check cell order
  if (parseCellReference(startCopy.value).col > parseCellReference(endCopy.value).col ||
    parseCellReference(startCopy.value).row > parseCellReference(endCopy.value).row) {
    errorMessage.header = "Please check copy range."
    errorMessage.message = "Start copy cell should be top left of copy range."
    errorMessage.show = true
    return
  }

  //check for overlapping cells
  if (checkOverlappingCells(sheetCopy.value, sheetPaste.value, startCopy.value, endCopy.value, startPaste.value)) {
    console.log("overlapping cells")
    /* errorMessage.header = "Please check copy and paste ranges."
    errorMessage.message = "Copy and paste ranges overlap. At this time it is not recommended to proceed."
    errorMessage.show = true
    return */
  }

  //make it so
  copyPaste({
    sheetCopy: sheetCopy.value,
    sheetPaste: sheetPaste.value,
    startCopy: startCopy.value,
    endCopy: endCopy.value,
    copyRange: `${startCopy.value}:${endCopy.value}`,
    startPaste: startPaste.value,
    pasteValues: pasteValues.value,
    formulas: formulas.value,
    formats: formats.value,
    fullCopy: fullCopy.value,
    skipBlanks: skipBlanks.value,
    exactCopy: exactCopy.value,
    pasteAtSel: pasteAtSel.value,
  })


  //after process, save settings to localStore and to in spreadsheet dataStore
  copyPasteToolSettings.startPaste = startPaste.value;
  copyPasteToolSettings.pasteValues = pasteValues.value;
  copyPasteToolSettings.formulas = formulas.value;
  copyPasteToolSettings.formats = formats.value;
  copyPasteToolSettings.fullCopy = fullCopy.value;
  copyPasteToolSettings.startCopy = startCopy.value;
  copyPasteToolSettings.endCopy = endCopy.value;
  copyPasteToolSettings.startPaste = startPaste.value;
  copyPasteToolSettings.sheetCopy = sheetCopy.value;
  copyPasteToolSettings.sheetPaste = sheetPaste.value;
  copyPasteToolSettings.skipBlanks = skipBlanks.value;
  copyPasteToolSettings.exactCopy = exactCopy.value;
  copyPasteToolSettings.pasteAtSel = pasteAtSel.value;


  saveCopyPaste();
}

async function saveCopyPaste() {
  //save data to the office/excel store.  
  await dataStore("store", "pasteValues", pasteValues.value);
  await dataStore("store", "formulas", formulas.value);
  await dataStore("store", "formats", formats.value);
  await dataStore("store", "fullCopy", fullCopy.value);
  await dataStore("store", "startCopy", startCopy.value);
  await dataStore("store", "endCopy", endCopy.value);
  await dataStore("store", "startPaste", startPaste.value);
  await dataStore("store", "sheetCopy", sheetCopy.value);
  await dataStore("store", "sheetPaste", sheetPaste.value);
  await dataStore("store", "skipBlanks", skipBlanks.value);
  await dataStore("store", "exactCopy", exactCopy.value);
  await dataStore("store", "pasteAtSel", pasteAtSel.value);
}

function opCheck(option) {
  //Options: ['pasteValues', 'formulas','multi', 'copyPaste', 'fillFunc']
  //check if option was selected or deselected as part of click

  if (option == "formulas" && formulas.value == true) {
    pasteValues.value = false;
    fullCopy.value = false;
    formats.value = false;
  }
  if (option == "pasteValues" && pasteValues.value == true) {
    formulas.value = false;
    formats.value = false;
    fullCopy.value = false;
  }
  if (option == "formats" && formats.value == true) {
    formulas.value = false;
    pasteValues.value = false;
    fullCopy.value = false;
  }
  if (option == "fullCopy" && fullCopy.value == true) {
    formulas.value = false;
    pasteValues.value = false;
    formats.value = false;
  }
  if (
    fullCopy.value == false &&
    formulas.value == false &&
    pasteValues.value == false &&
    formats.value == false
  ) {
    fullCopy.value = true;
  }
  if (exactCopy.value == true) {
    skipBlanks.value = false
    formulas.value = false
    pasteValues.value = false
    formats.value = false
    fullCopy.value = false
  }
}

async function getCurrentSelection(inputBox) {
  //functions to get the currently selected row and column and apply it to the settings.
  const range = await window.Excel.run(async (context) => {
    const range = context.workbook.getSelectedRange();
    range.load("address");
    await context.sync();
    return range.address;
  });

  const [start, endCell] = range.split(':');
  const [startSheet, startCell] = start.split('!')

  if (inputBox === 'copy') {
    sheetCopy.value = startSheet
    startCopy.value = startCell;
    if (!endCell || !endCell.match(/[A-Z]+\d+/)) {
      endCopy.value = startCell;
    } else {
      endCopy.value = endCell;
    }
  } else if (inputBox === 'paste') {
    sheetPaste.value = startSheet
    startPaste.value = startCell;
  }
}
</script>
<style scoped>
.custom-card ::v-deep .p-card-body {
  background-color: #f7ffab;
  color: #252523;
  padding: 5px;
  text-align: left;

}
</style>
