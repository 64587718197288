<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="main m-1 text-center">
    <div class="header">
      <p><Button label="Text Case Tools" @click="textCaseChange()" raised /></p>
    </div>
    <div class="grid m-1" v-if="textCase">
      <Card class="custom-card" v-if="helpToggle.show">
        <template #content> Select the cells you want to change the case of. Then click the button of the desired case
          changing action. </template>
      </Card>
      <div class="col-6 align-items-center">
        <Button label="Uppercase" @click="uppercase()" severity="info" style="min-width: 100%; min-height: 100%"
          raised />
      </div>
      <div class="col-6 align-items-center">
        <Button label="Proper Case" @click="properCase()" severity="info" style="min-width: 100%; min-height: 100%"
          raised />
      </div>
      <div class="col-6 align-items-center">
        <Button label="Sentence Case" @click="sentenceCase()" severity="info"
          style="min-width: 100%; min-height: 100%" />
      </div>
      <div class="col-6 align-items-center">
        <Button label="Lower Case" @click="lowerCase()" severity="info" style="min-width: 100%; min-height: 100%"
          raised />
      </div>
    </div>
    <div class="header">
      <p><Button label="Text Trimming and Splitting Tools" @click="textTrimChange()" raised /></p>
    </div>
    <div class="grid m-1" v-if="textTrim">
      <Card class="custom-card" v-if="helpToggle.show">
        <template #content>
          <p>Select the cells you want to modify. Then click the button of the desired action.</p>
          <p>For the "Slice Cell Characters" and "Split Cells at Character" tools, you will be prompted to enter
            additional information before the action takes place.</p>
          <p>For slicing, 1 is the first character, counting from left. For splitting, the first occurrencee from left
            of the character(s) is the delimiter used to split.</p>
        </template>
      </Card>
      <div class="col-6 align-items-center">
        <Button label="Trim Extra Spaces" @click="trimExtraSpaces()" severity="info"
          style="min-width: 100%; min-height: 100%" raised />
      </div>
      <div class="col-6 align-items-center">
        <Button label="Slice Cell Characters" @click="sliceAsk()" severity="info"
          style="min-width: 100%; min-height: 100%" raised />
      </div>
      <div class="col-6 align-items-center">
        <Button label="Split Cells at Character" @click="splitAsk()" severity="info"
          style="min-width: 100%; min-height: 100%" />
      </div>
    </div>
    <div class="header">
      <p>
        <Button label="Cell Format Tools" @click="cellFormatChange()" raised />
      </p>
    </div>
    <div class="grid m-1" v-if="cellFormat">
      <Card class="custom-card" v-if="helpToggle.show">
        <template #content> Select the cells you want to change the format of. Then click the button of the desired
          format changing action. </template>
      </Card>
      <div class="col-6 align-items-center">
        <Button label="Alternate Row Colors" @click="alternateColors()" severity="info"
          style="min-width: 100%; min-height: 100%" raised />
      </div>
      <div class="col-6 align-items-center">
        <Button label="Quick Border" @click="border()" severity="info" style="min-width: 100%; min-height: 100%"
          raised />
      </div>
    </div>
    <div class="header">
      <p>
        <Button label="Range Editing Tools" @click="rangeEditChange()" raised />
      </p>
    </div>
    <div class="grid m-1" v-if="rangeEdit">
      <Card class="custom-card" v-if="helpToggle.show">
        <template #content> Select the item below to enter the submenu for that tool. </template>
      </Card>
      <div class="col-6 align-items-center">
        <router-link to="/CopyPaste">
          <Button label="Copy/Paste Tools" severity="info" style="min-width: 100%; min-height: 100%" raised />
        </router-link>
      </div>
      <div class="col-6 align-items-center">
        <router-link to="/FillMagic">
          <Button label="Fill Magic" severity="info" style="min-width: 100%; min-height: 100%" raised />
        </router-link>
      </div>
    </div>
    <div class="main">
      <p>
        <Button label="Custom Functions" @click="customFuncChange()" raised />
      </p>
    </div>
    <div class="grid" v-if="customFunc">
      <Card class="custom-card" v-if="helpToggle.show">
        <template #content>
          <p>These tool makes use of lambda functions within Excel. It makes it easy to create custom excel functions
            for common calculations, but doesn't require macros or VBA.
          </p>
          <p>
            You can see what custom functions you have added by going to the ribbon and selecting 'Formulas' > 'Name
            Manager'.</p>
          <p> Best of all, they stay with your workbook, so you can share it with others and they can use the functions
            too!
          </p>


        </template>
      </Card>
      <div class="col-6 align-items-center">
        <router-link to="/UnitConvert">
          <Button label="Unit Conversions" severity="info" style="min-width: 100%; min-height: 100%" raised />
        </router-link>
      </div>
      <div class="col-6 align-items-center">
        <Button label="Velocity" severity="info" style="min-width: 100%; min-height: 100%"
          @click="quickLambda('velocity')" raised />
      </div>
      <div class="col-6 align-items-center">
        <Button label="Diameter from Area" severity="info" style="min-width: 100%; min-height: 100%"
          @click="quickLambda('dia')" raised />
      </div>
      <div class="col-6 align-items-center">
        <Button label="Alum Sludge Density" severity="info" style="min-width: 100%; min-height: 100%"
          @click="quickLambda('sludge')" raised />
      </div>
    </div>
    <div class="col-12 align-items-center">
      <router-link to="/AskGPT">
        <Button label="Ask Chat GPT" raised />
      </router-link>
    </div>
  </div>
  <ColorDialog />
  <LambdaConfirmed />
  <ErrorDialog />
  <QuickInput />
</template>

<script setup>
import ColorDialog from "../components/ColorDialog.vue";
import LambdaConfirmed from "../components/LambdaConfirmed.vue";
import ErrorDialog from "../components/ErrorDialog.vue";
import QuickInput from "../components/QuickInput.vue";
import {
  uppercase,
  sentenceCase,
  properCase,
  lowerCase,
} from "../methods/changeCase.js";
import { border } from "../methods/border.js";
import { lambdaFunc } from "../methods/lambda.js";
import { colorDialog, helpToggle, lambdaConfirm, quickInput } from "../methods/store.js";
import { trimExtraSpaces, sliceCells, splitCells } from "../methods/trimCell";
import Card from 'primevue/card';
import { ref, watch } from "vue";

var textCase = ref(false);
var cellFormat = ref(false);
var rangeEdit = ref(false);
var customFunc = ref(false);
var textTrim = ref(false);

function textCaseChange() {
  textCase.value == true ? (textCase.value = false) : (textCase.value = true);
}

function textTrimChange() {
  textTrim.value == true ? (textTrim.value = false) : (textTrim.value = true);
}

function cellFormatChange() {
  cellFormat.value == true
    ? (cellFormat.value = false)
    : (cellFormat.value = true);
}

function rangeEditChange() {
  rangeEdit.value == true
    ? (rangeEdit.value = false)
    : (rangeEdit.value = true);
}

function customFuncChange() {
  customFunc.value == true
    ? (customFunc.value = false)
    : (customFunc.value = true);
}

function alternateColors() {
  colorDialog.show = true;
}

async function quickLambda(type) {
  var result = { msg: "Error. Something Bad Happened" };
  if (type == "dia") {
    result = await lambdaFunc(
      type,
      `=LAMBDA(area,(SQRT((area*4)/PI())))`,
      "Enter area in sqft. Get approximate diameter in inches"
    );
  }
  if (type == "sludge") {
    result = await lambdaFunc(
      "sludge_density",
      `=LAMBDA(percent_solids,(6.465*percent_solids^3-0.534*percent_solids^2+5.371*percent_solids+8.337))`,
      "Enter percent solids, get approximate sludge density"
    );
  }
  if (type == "velocity") {
    result = await lambdaFunc(
      "velocity",
      `=LAMBDA(size_in,flow_gpm,(flow_gpm*0.002228)/(3.14159*(size_in/12)^2/4))`,
      "Enter size in inches and flow in GPM. Get velocity in feet per second"
    );
  }
  lambdaConfirm.result = result.msg;
  lambdaConfirm.show = true;
}

async function sliceAsk() {
  quickInput.show = true
  quickInput.title = "Slice Cells"
  quickInput.inputLabels = ["Character Number to Start Slice From", "Number of Characters to Keep"]
  quickInput.inputTypes = ["number", "number"]
  quickInput.inputValues = [2, 5]
  // Wait for modal to close before proceeding
  await new Promise(resolve => {
    const unwatch = watch(() => quickInput.show, (newValue) => {
      if (!newValue) {
        unwatch()
        resolve()
      }
    })
  })
  // Proceed with the function after modal is closed
  if (quickInput.approve) {
    await sliceCells(quickInput.inputValues[0], quickInput.inputValues[1])
  } else {
    console.log("Slice Cells Cancelled By User")

  }
}

async function splitAsk() {
  quickInput.show = true
  quickInput.title = "Split Cells"
  quickInput.inputLabels = ["Character(s) to Split On", "Keep Text Left of Split", "Keep Text Right of Split", "Keep Split Character(s) with Left", "Keep Split Character(s) with Right"]
  quickInput.inputValues = [quickInput.inputValues[0] || "<Split On>",
  quickInput.inputValues[1] || true,
  quickInput.inputValues[2] || true,
  quickInput.inputValues[3] || true,
  quickInput.inputValues[4] || false]
  quickInput.inputTypes = ["text", "checkbox", "checkbox", "checkbox", "checkbox"]
  // Wait for modal to close before proceeding
  await new Promise(resolve => {
    const unwatch = watch(() => quickInput.show, (newValue) => {
      if (!newValue) {
        unwatch()
        resolve()
      }
    })
  })
  // Proceed with the function after modal is closed
  if (quickInput.approve) {
    await splitCells(quickInput.inputValues[0], quickInput.inputValues[1], quickInput.inputValues[2], quickInput.inputValues[3], quickInput.inputValues[4])
  } else /******/
    console.log("Split Cells Cancelled By User")
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* If you want to target the inner content area of the card */
.custom-card ::v-deep .p-card-body {
  background-color: #f7ffab;
  color: #252523;
  padding: 5px;

}

.text-right {
  text-align: right;
}
</style>
