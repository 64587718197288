<template>
    <div class="main m-1 text-center text-100 text-xl">
        <h1>Ask ChatGPT</h1>
        <div class="field grid text-base p-1 align-items-center">

            <div class="grid">
                <div class="col-12 align-items-center pb-3">
                    <div class="pb-2">Type Your Query for ChatGPT:</div>
                    <Textarea v-model="question" placeholder="Type your question here" id="questionInput" class="w-full"
                        rows="5" /><br />
                </div>
                <div class="col-6 align-items-right text-right p-y1">
                    Include highlighted <br />cells as context?
                </div>
                <div class="col-6 align-items-center text-left vertical-align-middle	p-y1">
                    <Checkbox v-model="includeContext" id="includeContext" binary variant="filled" />
                </div>
                <div class="col-12 align-items-center py-3">
                    <Message severity="warn" :closable="true">
                        <strong>This feature queries ChatGPT/OpenAI. Do not include proprietary information.</strong>
                    </Message>
                </div>
                <div class="col-12 align-items-center pt-2">
                    <Button label="*** Ask *** " icon="pi pi-send" @click="askQuestion" class="w-full" raised />
                </div>
                <div v-if="gptResponse.value !== ''" class="pt-3">
                    <Card class="custom-card">
                        <template #title>GPT Response</template>
                        <template #content>
                            <div v-dompurify-html="gptResponse.html" class="text-left width-control"></div>
                        </template>
                    </Card>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import Textarea from 'primevue/textarea';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import Message from 'primevue/message'
import Card from 'primevue/card'
import { marked } from 'marked';
import { onAskQuestionButtonClick } from '../methods/askgpt.js';
import { gptResponse } from '../methods/store.js';

const question = ref('');
const includeContext = ref();

watch(gptResponse, (response) => {
    gptResponse.html = marked.parse(response.value['answer'])
})

const askQuestion = async () => {
    try {
        gptResponse.response = await onAskQuestionButtonClick(question.value, includeContext.value);
    } catch (error) {
        console.error('Error:', error)
        gptResponse.response = 'An error occurred while processing your request.'
    }
};

</script>
<style scoped>
/* If you want to target the inner content area of the card */
.custom-card ::v-deep .p-card-body {
    background-color: #fafafa;
    color: #000000;
    padding: 7px;
    max-width: 100%;
    border: 3px;
    border-color: #3f51b5;
    border-style: solid;
    border-radius: 10px;
    margin: 7px;
}

:deep code {
    font-family: monospace;
    background-color: #dbdbdb;
    scroll-behavior: smooth;
    /* overflow: scroll; */
    display: inline-flex;
    width: 100%;
    padding: 3px;
    /* flex-wrap: nowrap; */
    text-wrap-mode: wrap;
}

.text-left {
    text-align: left;
}
</style>